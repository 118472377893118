import React from "react";
import RequestButton from "../RequestButton";

const WeightLossInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          Weight Loss Q & A
        </p>
        <p className="text-xl text-gray-800 mb-2">
          What is medical weight loss?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Medical weight loss with the Sunstate Medical Associates team is
          weight loss supervised by experienced primary care physicians. They
          offer:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Meal plans</li>
          <li>Diet and exercise advice</li>
          <li>Lifestyle recommendations</li>
          <li>Medications when necessary</li>
          <li>Disease management</li>
          <li>Hormone therapy</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Your weight loss provider helps you manage an illness or disease or
          prevent chronic diseases from developing. They tailor each treatment
          based on your initial body weight, health, lifestyle, and food
          preferences.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the risk factors for obesity?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Obesity is common and anyone can develop it, but certain factors
          increase your risk of being overweight. Examples include:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Physical inactivity</li>
          <li>Sugar-sweetened drink consumption</li>
          <li>Unhealthy food choices</li>
          <li>Hormone imbalance</li>
          <li>Family history of obesity</li>
          <li>Older age</li>
          <li>Some medications</li>
          <li>Chronic stress</li>
          <li>Sleep deprivation</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Participating in a medically supervised weight loss program
          significantly boosts your odds of getting and keeping excess weight
          off.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What are the benefits of weight loss?
        </p>
        <p className="text-md text-gray-600 mb-2">
          If you’re overweight or obese, weight loss reduces your risk of
          developing:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Heart attack or stroke</li>
          <li>Heart disease</li>
          <li>Type 2 diabetes</li>
          <li>Cancer</li>
          <li>Sexual problems</li>
          <li>Gynecological issues</li>
          <li>Osteoarthritis</li>
          <li>Digestive problems</li>
          <li>Depression</li>
          <li>Sleep apnea</li>
          <li>Disability</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Weight loss can improve your overall quality of life by boosting your
          energy, mood, and ability to stay active and effectively perform job
          duties.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          What happens during medical weight loss?
        </p>
        <p className="text-md text-gray-600 mb-2">
          During your first weight loss appointment at Sunstate Medical
          Associates your doctor checks your vital signs, weighs you, and
          discusses your medical history including medications, past and current
          diagnoses, and surgeries.
        </p>
        <p className="text-md text-gray-600 mb-2">
          They complete a physical exam and use blood tests to evaluate thyroid,
          other hormone levels, blood sugar, cholesterol, and more and find out
          if you require medical intervention in addition to weight loss
          counseling. Taking medications or dietary supplements can reduce your
          appetite to better control calories.
        </p>
        <p className="text-md text-gray-600 mb-12">
          You might attend weight loss appointments weekly at first and less
          often as you progress through your weight loss journey. Your primary
          care doctor makes adjustments to your diet and medications, or
          recommends additional lab tests when necessary.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          How much weight can I lose?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Many patients can lose up to five pounds weekly when following
          medically supervised weight loss programs. The amount of weight you
          can expect to lose depends on your initial weight, goal weight, and
          how well you adhere to your custom weight loss plan.
        </p>
        <p className="text-md text-gray-600 mb-10">
          Don’t let being overweight diminish your quality of life. Schedule a
          weight loss evaluation with Sunstate Medical Associates by phone or
          online today.
        </p>
        <RequestButton />
      </div>
    </div>
  );
};

export default WeightLossInfo;
