import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import ServicesHeader from "../../components/ServicesHeader";
import WeightLossInfo from "../../components/services/WeightLossInfo";

const WeightLoss = () => {
  return (
    <Layout>
      <PageHeader text="Weight Loss" />
      <ServicesHeader>
        Being overweight can take a toll on your energy levels, self-confidence,
        and overall quality of life. At Sunstate Medical Associates in Lake
        Mary, Florida, internal medicine experts offer medical weight loss
        tailored to your needs to help you reach your goal weight. Call the
        office today and speak with a friendly team member or use the online
        booking tool.
      </ServicesHeader>
      <WeightLossInfo />
      <Conditions />
    </Layout>
  );
};

export default WeightLoss;
